body {
  margin: 0px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  display: block;
}

.gifViewer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0px;
  right: 0px;
}

.gifViewer img {
  z-index: 99;
}

.search {
  position: fixed;
  display: block;
  top: 10px;
  left: 10px;
  z-index: 98;
}

.darken {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.65;
  z-index: 55;
}

.streamRow {
  display: flex;
  flex-direction: row;
}

.streamColumn div {
  display: flex;
  flex-direction: column;
}

.clickable {
  cursor: pointer;
}

button {
  border: 0px;
  padding: 0px;
}
